import { useRouter } from "@app-routes";
import { type NavbarConfigFragment } from "@gql-schema";
import { NavBarMenuButton, NavBarMenuButtonVariant } from "@ui/components/NavBar/nav-bar-menu-button";
import { useClickableProps } from "@uxf/core/hooks/useClickableProps";
import { isNotNil } from "@uxf/core/utils/is-not-nil";
import { HTMLAttributes } from "react";
import { DesktopLandingPageMenuItem } from "./desktop-landing-page-menu-item";
import styles from "./desktop-landing-page-menu.module.css";
import { DesktopPopUp } from "./desktop-pop-up";
import { usePopUpDataAtom, useResetPopUpDataAtom } from "./pop-up-data-atom";

interface DesktopLandingPageMenuProps {
    activeMenuItemIndex?: number;
    variant?: NavBarMenuButtonVariant;
    navbarData: NavbarConfigFragment;
}

export function DesktopLandingPageMenu(props: DesktopLandingPageMenuProps) {
    const variant = props.variant ?? "default";

    const [dataKey] = usePopUpDataAtom();

    const resetDataKey = useResetPopUpDataAtom();

    const router = useRouter();

    const popUpData = isNotNil(dataKey) ? props.navbarData.sections[dataKey] : null;

    const clickableProps = useClickableProps<HTMLAttributes<HTMLDivElement>>({
        className: styles.navbarMenuButtonBlock,
        // Reset data key on mouse enter to prevent submenu from other links to show up
        onMouseEnter: resetDataKey,
        onMouseOver: resetDataKey,
    });

    return (
        <>
            {props.navbarData.sections.map((item, index) => (
                <DesktopLandingPageMenuItem
                    isActive={index === props.activeMenuItemIndex}
                    data={props.navbarData}
                    index={index}
                    item={item}
                    key={index}
                    variant={variant}
                />
            ))}
            <div {...clickableProps}>
                <NavBarMenuButton
                    hasSubmenu={false}
                    isActive={router.route === "watch-wolf-zone/entry-page"}
                    variant={variant}
                    title="Hlídací vlk"
                    route="watch-wolf-zone/entry-page"
                    $fontWeight={500}
                />
            </div>
            {popUpData && <DesktopPopUp hasBorder={variant === "default"} data={popUpData} />}
        </>
    );
}
