import { TextContainer } from "@ui/components/Button/styles/TextContainer";
import { CLASSES } from "@uxf/core/constants/classes";
import { ColorProperty } from "@uxf/styles/types";
import { rem } from "@uxf/styles/utils/rem";
import { css } from "styled-components";

export const activeButtonDecoration = (color: ColorProperty) => css`
    &.${CLASSES.IS_ACTIVE} {
        @supports not ((text-underline-offset: 5px) or (text-decoration-thickness: 2px)) {
            ${TextContainer} {
                border-bottom: 2px solid ${color};
                border-top: 2px solid transparent;
            }
        }

        @supports ((text-underline-offset: 5px) and (text-decoration-thickness: 2px)) {
            text-decoration: ${color} underline solid 2px;
            text-underline-offset: ${rem(4)};
        }
    }
`;
