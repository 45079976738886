import { useAtom } from "jotai";
import { atomWithReset, useResetAtom } from "jotai/utils";

const popUpDataAtom = atomWithReset<number | null>(null);

export function usePopUpDataAtom() {
    return useAtom(popUpDataAtom);
}

export function useResetPopUpDataAtom() {
    return useResetAtom(popUpDataAtom);
}
