import { type NavbarConfigSectionColumnFragment, type NavbarConfigSectionFragment } from "@gql-schema";
import { buildArray } from "@uxf/core/utils/buildArray";
import { DesktopSubmenuColumn } from "./desktop-submenu-column";

interface DesktopSubmenuContentProps {
    data: NavbarConfigSectionFragment;
}

const EMPTY_COLUMN: NavbarConfigSectionColumnFragment = {
    __typename: "NavbarConfigSectionColumn",
    items: [],
};

export function DesktopSubmenuContent(props: DesktopSubmenuContentProps) {
    const columns = buildArray<NavbarConfigSectionColumnFragment>()
        .add(props.data.columns[0] ?? EMPTY_COLUMN)
        .add(props.data.columns[1] ?? EMPTY_COLUMN)
        .add(props.data.columns[2] ?? EMPTY_COLUMN)
        .add(props.data.columns[3] ?? EMPTY_COLUMN)
        .add(props.data.columns[4] ?? EMPTY_COLUMN);

    const isGreenSectionNotEmpty = columns[3].items.length > 0 || columns[4].items.length > 0;

    return (
        <div className="grid grid-cols-5">
            {columns.map((column, index) => (
                <DesktopSubmenuColumn
                    key={index}
                    data={column}
                    isGreen={(index === 3 || index === 4) && isGreenSectionNotEmpty}
                />
            ))}
        </div>
    );
}
