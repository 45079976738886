import { Button, ButtonProps } from "@ui/components/Button/Button";
import { ClickableProps } from "@ui/components/Clickable/Clickable";
import { COLORS } from "@ui/theme/colors";
import { CLASSES } from "@uxf/core/constants/classes";
import { cx } from "@uxf/core/utils/cx";
import { Margins } from "@uxf/styles/properties/margins";
import { memo, type CSSProperties } from "react";
import { activeButtonDecoration } from "./styles/activeButtonDecoration";

export type NavBarMenuButtonVariant = "white" | "black" | "default";

interface Props extends ClickableProps, Margins {
    hasSubmenu?: boolean;
    isActive?: boolean;
    title: string;
    variant?: NavBarMenuButtonVariant;
    $fontWeight?: CSSProperties["fontWeight"];
}

const BUTTON_COMMON_PROPS: Partial<ButtonProps> = {
    $color: "transparent",
    $fontSize: 14,
    $height: 28,
    $lineHeight: `${20 / 14}`,
    $px: 16,
    $hoverColor: "none",
    $textTransform: "uppercase",
};

function Component(props: Props) {
    const { className, hasSubmenu, isActive, variant = "default", title, ...restProps } = props;

    const _className = cx(isActive && CLASSES.IS_ACTIVE, className);

    if (variant === "white") {
        return (
            <Button
                {...BUTTON_COMMON_PROPS}
                $css={activeButtonDecoration(COLORS.yellow.default)}
                $focusColor={COLORS.white}
                $hoverColor={COLORS.yellow.default}
                $textColor={COLORS.white}
                $textHoverColor={COLORS.green.default}
                className={_className}
                {...restProps}
            >
                {title}
            </Button>
        );
    }

    if (variant === "black") {
        return (
            <Button
                {...BUTTON_COMMON_PROPS}
                $css={activeButtonDecoration(COLORS.green.default)}
                $focusColor={!hasSubmenu ? COLORS.green.default : undefined}
                $hoverColor={!hasSubmenu ? COLORS.green.dark : "none"}
                $textColor={COLORS.black}
                $textHoverColor={!hasSubmenu ? COLORS.yellow.default : undefined}
                className={_className}
                {...restProps}
            >
                {title}
            </Button>
        );
    }

    return (
        <Button
            {...BUTTON_COMMON_PROPS}
            $css={activeButtonDecoration(COLORS.yellow.default)}
            $focusColor={!hasSubmenu ? COLORS.green.default : undefined}
            $hoverColor={!hasSubmenu ? COLORS.yellow.default : "none"}
            $textColor={COLORS.green.default}
            className={_className}
            {...restProps}
        >
            {title}
        </Button>
    );
}

export const NavBarMenuButton = memo(Component);

NavBarMenuButton.displayName = "NavBarMenuButton";
