import { type NavbarConfigSectionColumnFragment } from "@gql-schema";
import { cx } from "@uxf/core/utils/cx";
import { DesktopSubmenuItem } from "./desktop-submenu-item";

interface DesktopSubmenuColumnProps {
    data: NavbarConfigSectionColumnFragment;
    isGreen?: boolean;
}

export function DesktopSubmenuColumn(props: DesktopSubmenuColumnProps) {
    const className = cx(
        "pb-12 pt-8 [&:not(:first-child)]:pl-4 [&:not(:last-child)]:pr-4 [&_*+*]:mt-4",
        props.isGreen && "bg-green-secondary",
    );

    if (props.data.items.length === 0) {
        return <div className={className} />;
    }

    return (
        <ul className={className}>
            {props.data.items.map((item, index) => (
                <DesktopSubmenuItem isTitle={index === 0} key={index} label={item.label} href={item.href} />
            ))}
        </ul>
    );
}
