import { type NavbarConfigFragment, type NavbarConfigSectionFragment } from "@gql-schema";
import { SeoSubmenu } from "@shared/components/Navigation/elements/seo-submenu";
import { NavBarMenuButton, NavBarMenuButtonVariant } from "@ui/components/NavBar/nav-bar-menu-button";
import { useBooleanWithRouteTransition } from "@ui/hooks/useBooleanWithRouteTransition";
import { useClickableProps } from "@uxf/core/hooks/useClickableProps";
import { cx } from "@uxf/core/utils/cx";
import { isNotNil } from "@uxf/core/utils/is-not-nil";
import { HTMLAttributes, useCallback, useEffect } from "react";
import { usePopUpDataAtom, useResetPopUpDataAtom } from "./pop-up-data-atom";

interface DesktopLandingPageMenuItemProps {
    activeMenuItemIndex?: number;
    data: NavbarConfigFragment | undefined;
    index: number;
    item: NavbarConfigSectionFragment;
    variant?: NavBarMenuButtonVariant;
    isActive: boolean;
}

export function DesktopLandingPageMenuItem(props: DesktopLandingPageMenuItemProps) {
    const [isOpen, setIsOpen] = useBooleanWithRouteTransition(false);
    const [dataKey, setDataKey] = usePopUpDataAtom();
    const resetDataKey = useResetPopUpDataAtom();

    const openPopUp = useCallback(() => {
        setDataKey(props.index);
        setIsOpen(true);
    }, [setDataKey, props.index, setIsOpen]);

    const _hasSubmenu = props.item.columns.length > 0;

    useEffect(() => {
        if (props.index !== dataKey) {
            setIsOpen(false);
        }
    }, [dataKey, props.index, setIsOpen]);

    const clickableProps = useClickableProps<HTMLAttributes<HTMLDivElement>>({
        className: cx(
            "flex items-center self-stretch",
            isOpen && _hasSubmenu ? (props.variant === "default" ? "bg-green-secondary" : "bg-white") : undefined,
        ),
        onMouseEnter: openPopUp,
        onMouseOver: openPopUp,
    });

    return (
        <div {...clickableProps}>
            <NavBarMenuButton
                hasSubmenu={_hasSubmenu}
                isActive={props.isActive}
                onClick={resetDataKey}
                variant={isOpen && _hasSubmenu ? (props.variant === "default" ? "default" : "black") : props.variant}
                title={props.item.label}
                href={props.item.href}
            />
            {isNotNil(props.data) && isNotNil(props.data.sections[props.index]) && (
                <SeoSubmenu data={props.data.sections[props.index]} />
            )}
        </div>
    );
}
