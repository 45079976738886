import { type NavbarConfigItemFragment } from "@gql-schema";
import { Link } from "@ui/components/Link/Link";

type SeoSubmenuLinkProps = NavbarConfigItemFragment;

export function SeoSubmenuLink(props: SeoSubmenuLinkProps) {
    try {
        return (
            <Link tabIndex={-1} href={props.href}>
                {props.label}
            </Link>
        );
    } catch {
        return null;
    }
}
