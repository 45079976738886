import { type NavbarConfigSectionFragment } from "@gql-schema";
import { useRafState } from "@uxf/core/hooks/useRafState";
import { cx } from "@uxf/core/utils/cx";
import { useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { DesktopSubmenuContent } from "./desktop-submenu-content";
import { hasSubmenu } from "./has-submenu";
import { useResetPopUpDataAtom } from "./pop-up-data-atom";

interface DesktopSubmenuPopUpProps {
    data: NavbarConfigSectionFragment;
    hasBorder?: boolean;
}

export function DesktopPopUp(props: DesktopSubmenuPopUpProps) {
    const anchorNode = useRef<HTMLDivElement>(null);
    const [offsetTop, setOffsetTop] = useRafState<number | undefined>(undefined);
    const resetDataKey = useResetPopUpDataAtom();

    useEffect(() => {
        function handleOffset() {
            const anchorElement = anchorNode.current;
            if (anchorElement) {
                const parentElement = anchorElement.parentElement;
                if (parentElement) {
                    setOffsetTop(parentElement.getBoundingClientRect().bottom);
                }
            }
        }

        handleOffset();
        window.addEventListener("scroll", handleOffset);
        return () => {
            window.removeEventListener("scroll", handleOffset);
        };
    }, [setOffsetTop]);

    return hasSubmenu(props.data) ? (
        <>
            <div ref={anchorNode} role="none" className="self-strech" />
            {createPortal(
                <div
                    aria-hidden
                    className={cx("fixed inset-x-0 z-fixed flex bg-white", props.hasBorder && "border-y border-green")}
                    onMouseLeave={resetDataKey}
                    style={offsetTop ? { top: offsetTop } : undefined}
                >
                    <div className="grow" />
                    <div className="container mx-auto w-full shrink-0 max-md:px-6">
                        <DesktopSubmenuContent data={props.data} />
                    </div>
                    <div className={`grow ${props.data.columns.length > 3 ? "bg-green-secondary" : ""}`} />
                </div>,
                document.body,
            )}
        </>
    ) : null;
}
