import { type NavbarConfigSectionColumnFragment, type NavbarConfigSectionFragment } from "@gql-schema";
import { hideVisually } from "@ui/mixins/hideVisually";
import { hasSubmenu } from "./has-submenu";
import { SeoSubmenuLink } from "./seo-submenu-link";

function renderColumn(data: NavbarConfigSectionColumnFragment, i: number) {
    if (data.items.length === 0) {
        return null;
    }

    return (
        <li key={i}>
            {data.items.length > 0 && (
                <ul>
                    {data.items.map((item, index) => (
                        <SeoSubmenuLink key={index} label={item.label} href={item.href} />
                    ))}
                </ul>
            )}
        </li>
    );
}

interface SeoSubmenuProps {
    data: NavbarConfigSectionFragment;
}

export function SeoSubmenu(props: SeoSubmenuProps) {
    if (!hasSubmenu(props.data)) {
        return null;
    }

    return (
        <ul aria-hidden="true" style={hideVisually}>
            {props.data.columns.map((column, index) => renderColumn(column, index))}
        </ul>
    );
}
